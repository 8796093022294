<template>
  <b-overlay
    :show="loading"
    style="min-height: 20vw; width: 100%"
    opacity="0.5"
  >
    <div
      class="documents-container"
    >
      <b-row>
        <b-col
          cols="12"
          class="search"
        >
          <b-input-group
            size="sm"
            class="mt-4 mb-4"
          >
            <template #prepend>
              <b-input-group-text>
                <b-icon
                  icon="search"
                  scale="0.8"
                />
              </b-input-group-text>
            </template>
            <b-form-input
              id="filter-input"
              v-model="filterDocuments"
              type="search"
              placeholder="Pesquisar..."
            />

            <b-input-group-append>
              <b-button
                :disabled="!filterDocuments"
                @click="filterDocuments = ''"
              >
                Limpar
              </b-button>
            </b-input-group-append>
          </b-input-group>
          <b-table
            id="documents"
            :items="documents"
            :per-page="perPage"
            :current-page="currentPageDocuments"
            :fields="fieldsDocuments"
            :filter="filterDocuments"
            :filter-included-fields="filterOnDocuments"
            borderless
            empty-text="Sem documentos"
            empty-filtered-text="Sem documentos"
            show-empty
            hover
            small
          >
            <template #cell(id)="data">
              <div class="buttons m-0 p-0 justify-content-start buttons-group">
                <div
                  class="button primary xs"
                  @click="downloadDocument(data.item.id)"
                >
                  <b-icon
                    icon="download"
                    scale="1.2"
                  />
                </div>
              </div>
            </template>
          </b-table>
          <b-pagination
            v-model="currentPageDocuments"
            :total-rows="rowsDocuments"
            :per-page="perPage"
            aria-controls="users"
          />
        </b-col>
      </b-row>
    </div>
  </b-overlay>
</template>

<script>
import { get } from '../../services/api';

export default {
  name: 'DashboardDocuments',
  data() {
    return {
      awaitResponse: false,
      documents: null,
      filterDocuments: null,
      loading: true,
      perPage: 10,
      currentPageDocuments: 1,
      fieldsDocuments: [
        {
          key: 'name', sortable: true, label: 'Nome', thStyle: { width: '90%' },
        },
        { key: 'id', label: 'Ações', thStyle: { width: '10%' } },
      ],
      filterOnDocuments: ['name'],

    };
  },
  computed: {

    rowsDocuments() {
      if (this.documents) {
        return this.documents.length;
      }
      return 0;
    },
  },
  async created() {
    this.awaitResponse = true;
    await this.getDocuments();
    this.loading = false;
  },
  methods: {
    async getDocuments() {
      await get('document/').then((response) => {
        this.awaitResponse = false;
        this.documents = response.data;
      }).catch(() => {
        this.awaitResponse = false;
      });
    },
    async downloadDocument(id) {
      const file = this.documents.filter((element) => element.id === id)[0];
      const linkSource = `data:application/${file.name.split('.')[-1]};base64,${file.file}`;
      const downloadLink = document.createElement('a');
      const fileName = file.name;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    },
  },
};
</script>
